import React, { useEffect, useRef, useState } from "react";

import { ArrowUpIcon } from "@heroicons/react/24/outline";

import { InView } from "react-intersection-observer";
import TextareaAutosize from "react-textarea-autosize";

import NavBar, { item } from "components/NavBar/NavBar";

import { ReactComponent as HTML5Logo } from "icons/html-5-logo.svg";
import { ReactComponent as CSS3Logo } from "icons/css-3-logo.svg";
import { ReactComponent as JSLogo } from "icons/javascript-logo.svg";
import { ReactComponent as TSLogo } from "icons/typescript-logo.svg";
import { ReactComponent as NodeJSLogo } from "icons/nodejs-logo.svg";
import { ReactComponent as PostgreSQLLogo } from "icons/postgresql-logo.svg";
import { ReactComponent as PrismaLogo } from "icons/prisma-logo.svg";
import { ReactComponent as ReactLogo } from "icons/react-logo.svg";
import { ReactComponent as TailwindLogo } from "icons/tailwind-logo.svg";
import { ReactComponent as StorybookLogo } from "icons/storybook-logo.svg";
import { ReactComponent as LinkedinLogo } from "icons/linkedin-logo.svg";
import { ReactComponent as AWSLogo } from "icons/aws-logo.svg";

import "./App.css";
import "./tailwind.css";
import clsx from "clsx";
import Form from "components/Form";
import { SubmitHandler } from "react-hook-form";
import Input from "components/Input/Input";
import AnchorLink from "react-anchor-link-smooth-scroll";

const itemsNavBar: item[] = [
  {
    title: "About",
    href: "#about",
  },
  {
    title: "Skills",
    href: "#skills",
  },
  {
    title: "Experience",
    href: "#experience",
  },
  {
    title: "Contact",
    href: "#contact",
  },
];

type experience = {
  company: string;
  period: string;
  url: string;
  description: String | React.ReactNode;
};

const BrikExperience = () => (
  <div className="text-slate-600">
    <h4 className="text-lg font-bold">Full stack developer</h4>
    <ul>
      <li>Conception, réalisation d'une API (NodeJS, Prisma, Postgresql)</li>
      <li>Plug sur des APIs externes (stripe, luko, idCheckIO...)</li>
      <li>Réalisation from scratch des landings pages (NextJs, Figma)</li>
      <li>
        Développement de la partie Saas et Marketplace (React, tailwind,
        react-hook-form...)
      </li>
      <li>Développement, conception du backoffice</li>
      <li>Gestion agile (scrum, linear)</li>
    </ul>
  </div>
);

const InetumExperience = () => (
  <div className="text-slate-600">
    <h4 className="text-lg font-bold">Front end developer</h4>
    <ul>
      <li>
        Développement des interfaces graphiques pour le client BNP Paribas
      </li>
      <li>Rédaction des spécifications techniques et fonctionelles</li>
      <li>
        Formation des nouveaux arrivants au framework SpiritJS (propriétaire BNP
        Paribas)
      </li>
      <li>Pilotage projets, communication, revue de code</li>
      <li>Gestion projets (Jira, Confluence)</li>
    </ul>
  </div>
);

const experiences: experience[] = [
  {
    company: "Brik",
    period: "Février 2021 - Février 2023",
    url: "https://www.brik.com",
    description: <BrikExperience />,
  },
  {
    company: "Inetum",
    period: "Septembre 2018 - Février 2021",
    url: "https://wwww.inetum.com",
    description: <InetumExperience />,
  },
];

const EMAIL = "place.martin@gmail.com";

type FormValues = {
  subject?: string;
  body?: string;
};

function App() {
  const [openNavBar, setOpenNavBar] = useState(false);
  const scrolluUpButtonRef = useRef<HTMLButtonElement>(null);

  const OnSubmit: SubmitHandler<FormValues> = (data) => {
    return (window.location = `mailto:${EMAIL}?subject=${
      data.subject
    }&body=${encodeURIComponent(data.body || "")}` as unknown as Location);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 400) {
        scrolluUpButtonRef?.current?.classList.remove("opacity-0");
        scrolluUpButtonRef?.current?.classList.add("opacity-100");
      } else {
        scrolluUpButtonRef?.current?.classList.add("opacity-0");
        scrolluUpButtonRef?.current?.classList.remove("opacity-100");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className="z-20 flex flex-col items-center w-full h-full pb-20 bg-gray-100 md:bg-gray-200">
        <NavBar open={openNavBar} setOpen={setOpenNavBar} items={itemsNavBar} />
        <section className="flex flex-col items-center w-full space-y-10 bg-indigo-500 mt-14 py-14">
          <div className="rounded-full h-36 w-36 pulse">
            <img
              src="photo.png"
              className="h-full p-[1px] rounded-full"
              alt="face cv"
            />
          </div>
          <div className="text-center text-white">
            <h1 className="text-4xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-orange-300">
              Martin Place
            </h1>
            <h2 className="text-xl text-transparent bg-clip-text bg-gradient-to-r to-purple-400 from-orange-300">
              Full stack JS web developer
            </h2>
          </div>
          <div className="flex space-x-2 text-white">
            <AnchorLink
              href="#contact"
              className="header-button text-center !bg-gradient-to-r !from-purple-400 !to-orange-300"
            >
              Hire me
            </AnchorLink>
            <a
              href="cv.pdf"
              target="_blank"
              className="header-button bg-gradient-to-r !to-purple-400 !from-orange-300"
            >
              Download CV
            </a>
          </div>
        </section>
        <div id="about">
          <InView threshold={0} triggerOnce>
            {({ ref, inView }) => {
              return (
                <section
                  ref={ref}
                  className={clsx(
                    "max-w-screen md:max-w-screen-lg p-5 md:mx-10 md:mt-10 bg-gray-100 md:rounded-lg md:shadow-2xl transition transform duration-500 grid grid-cols-2 md:gap-8 gap-16",
                    {
                      "ease-in opacity-100": inView,
                      "opacity-0": !inView,
                    }
                  )}
                >
                  <div className="col-span-2 md:col-span-1">
                    <h3 className="mb-5 text-2xl font-semibold">About</h3>
                    <p className="text-base font-light text-justify text-slate-500">
                      Autoditacte et passioné par le développement web depuis
                      2017 je recherche actuellement un nouveau challenge auquel
                      apporter ma contribution. Au fil des dernières années j’ai
                      eu l’occasion de travailler en ESN puis en startup le tout
                      en gestion agile.
                    </p>
                  </div>
                  <div className="col-span-2 md:col-span-1">
                    <h3 className="mb-5 text-2xl font-semibold">
                      Basic information
                    </h3>
                    <div className="grid items-center grid-cols-4 gap-3 text-slate-500">
                      <p className="col-span-1">Age: </p>
                      <p className="col-span-3">38</p>
                      <p className="col-span-1">Email: </p>
                      <div className="flex items-center col-span-3 space-x-2">
                        <a href={`mailto:${EMAIL}`}>{EMAIL}</a>
                      </div>
                      <p className="col-span-1">Phone: </p>
                      <div className="flex items-center col-span-3 space-x-2">
                        <p>07 68 33 46 82</p>
                      </div>
                      <StorybookLogo className="w-5 h-5 col-span-1" />
                      <a
                        href="https://storybook-martinplace.vercel.app/"
                        target="_blank"
                        rel="noreferrer"
                        className="col-span-3"
                      >
                        https://storybook-martinplace.vercel.app/ (en cours)
                      </a>
                      <LinkedinLogo className="w-5 h-5 col-span-1" />
                      <a
                        href="https://www.linkedin.com/in/martin-place/"
                        target="_blank"
                        rel="noreferrer"
                        className="col-span-3"
                      >
                        https://www.linkedin.com/in/martin-place/
                      </a>
                    </div>
                  </div>
                </section>
              );
            }}
          </InView>
        </div>
        <div id="skills" className="flex justify-center w-full">
          <InView threshold={0}>
            {({ ref, inView }) => {
              return (
                <section
                  ref={ref}
                  className={clsx(
                    "max-w-screen md:max-w-screen-lg p-5 md:mx-10 md:mt-10 bg-gray-100 md:rounded-lg md:shadow-2xl w-full transition transform duration-500 ",
                    {
                      "ease-in-out opacity-100 translate-y-0": inView,
                      "opacity-0 translate-y-40": !inView,
                    }
                  )}
                >
                  <h3 className="col-span-2 mb-5 text-2xl font-semibold">
                    Skills
                  </h3>
                  <div className="grid grid-cols-2 gap-10">
                    <div className="flex items-center col-span-2 space-x-3 md:col-span-1">
                      <HTML5Logo className="w-5 h-5" />
                      <div className="w-full bg-teal-600 border rounded-full">
                        <div className="w-10/12 col-span-1 md:col-span-2">
                          <div className="progress progress-striped">
                            {inView && <div className=" progress-bar"></div>}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center col-span-2 space-x-3 md:col-span-1">
                      <CSS3Logo className="w-5 h-5" />
                      <div className="w-full bg-teal-600 border rounded-full">
                        <div className="w-9/12 col-span-1 md:col-span-2">
                          <div className="progress progress-striped">
                            {inView && <div className=" progress-bar"></div>}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center col-span-2 space-x-3 md:col-span-1">
                      <TailwindLogo className="w-5 h-5" />
                      <div className="w-full bg-teal-600 border rounded-full">
                        <div className="w-10/12 col-span-1 md:col-span-2">
                          <div className="progress progress-striped">
                            {inView && <div className=" progress-bar"></div>}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center col-span-2 space-x-3 md:col-span-1">
                      <JSLogo className="w-5 h-5" />
                      <div className="w-full bg-teal-600 border rounded-full">
                        <div className="w-9/12 col-span-1 md:col-span-2">
                          <div className="progress progress-striped">
                            {inView && <div className=" progress-bar"></div>}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center col-span-2 space-x-3 md:col-span-1">
                      <TSLogo className="w-5 h-5" />
                      <div className="w-full bg-teal-600 border rounded-full">
                        <div className="w-9/12 col-span-1 md:col-span-2">
                          <div className="progress progress-striped">
                            {inView && <div className=" progress-bar"></div>}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center col-span-2 space-x-3 md:col-span-1">
                      <ReactLogo className="w-5 h-5" />
                      <div className="w-full bg-teal-600 border rounded-full">
                        <div className="w-10/12 col-span-1 md:col-span-2">
                          <div className="progress progress-striped">
                            {inView && <div className=" progress-bar"></div>}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center col-span-2 space-x-3 md:col-span-1">
                      <NodeJSLogo className="w-5 h-5" />
                      <div className="w-full bg-teal-600 border rounded-full">
                        <div className="w-8/12 col-span-1 md:col-span-2">
                          <div className="progress progress-striped">
                            {inView && <div className=" progress-bar"></div>}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center col-span-2 space-x-3 md:col-span-1">
                      <PrismaLogo className="w-5 h-5" />
                      <div className="w-full bg-teal-600 border rounded-full">
                        <div className="w-9/12 col-span-1 md:col-span-2">
                          <div className="progress progress-striped">
                            {inView && <div className=" progress-bar"></div>}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center col-span-2 space-x-3 md:col-span-1">
                      <PostgreSQLLogo className="w-5 h-5" />
                      <div className="w-full bg-teal-600 border rounded-full">
                        <div className="w-7/12 col-span-1 md:col-span-2">
                          <div className="progress progress-striped">
                            {inView && <div className=" progress-bar"></div>}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center col-span-2 space-x-3 md:col-span-1">
                      <AWSLogo className="w-5 h-5" />
                      <div className="w-full bg-teal-600 border rounded-full">
                        <div className="w-4/12 col-span-1 md:col-span-2">
                          <div className="progress progress-striped">
                            {inView && <div className=" progress-bar"></div>}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              );
            }}
          </InView>
        </div>
        <div id="experience" className="flex justify-center w-full">
          <InView threshold={0}>
            {({ inView, ref }) => (
              <section
                ref={ref}
                className={clsx(
                  "max-w-screen w-full md:max-w-screen-lg overflow-hidden p-5 md:mx-10 md:mt-10 bg-gray-100 md:rounded-lg md:shadow-2xl transition transform duration-500 ",
                  {
                    "ease-in-out opacity-100 translate-y-0": inView,
                    "opacity-0 translate-y-40": !inView,
                  }
                )}
              >
                <h3 className="mb-5 text-2xl font-semibold">Experience</h3>
                <div className="grid grid-cols-5 md:gap-y-5 ">
                  {experiences.map((experience) => (
                    <>
                      <div
                        className={clsx(
                          "col-span-5 p-5 overflow-hidden text-center text-white bg-indigo-500 rounded-t-lg md:col-span-2 md:rounded-s-lg md:rounded-tr-none transition duration-700 flex items-center",
                          {
                            "ease-in-out translate-x-0": inView,
                            "-translate-x-40": !inView,
                          }
                        )}
                      >
                        <div className="w-full">
                          <p>{experience.period}</p>
                          <a
                            href={experience.url}
                            target="_blank"
                            rel="noreferrer"
                            className="underline cursor-pointer hover:text-blue-400"
                          >
                            {experience.company}
                          </a>
                        </div>
                      </div>
                      <div
                        className={clsx(
                          "col-span-5 p-5 overflow-hidden transform duration-700 text-black bg-white rounded-b-lg md:col-span-3 md:rounded-e-lg mb-5 md:mb-0",
                          {
                            "ease-in-out translate-x-0": inView,
                            "translate-x-96": !inView,
                          }
                        )}
                      >
                        <div>
                          <p>{experience.description}</p>
                        </div>
                      </div>
                    </>
                  ))}
                </div>
              </section>
            )}
          </InView>
        </div>
        <div id="contact" className="flex justify-center w-full">
          <InView threshold={0}>
            {({ inView, ref }) => (
              <section
                ref={ref}
                className={clsx(
                  "max-w-screen w-full  md:max-w-screen-lg p-5 md:mx-10 md:mt-10 bg-gray-100 md:rounded-lg md:shadow-2xl transition transform duration-500 ",
                  {
                    "ease-in-out opacity-100 translate-y-0": inView,
                    "opacity-0 translate-y-40": !inView,
                  }
                )}
              >
                <h3 className="mb-5 text-2xl font-semibold">Contact</h3>
                <Form<FormValues> onSubmit={OnSubmit}>
                  {({ register }) => {
                    return (
                      <>
                        <div className="grid w-full grid-cols-2 gap-4">
                          <div className="col-span-2">
                            <label className="text-sm text-slate-500">
                              Objet:
                            </label>
                            <Input
                              {...register("subject")}
                              className="w-full h-10 p-2 border border-gray-300 rounded-md focus:outline-0"
                              type="input"
                            />
                          </div>
                          <div className="col-span-2">
                            <label className="text-sm text-slate-500">
                              Message:
                            </label>
                            <TextareaAutosize
                              minRows={5}
                              cacheMeasurements
                              className="w-full h-auto p-2 border border-gray-300 rounded-md focus:outline-0"
                              {...register("body")}
                            />
                          </div>
                        </div>
                        <div className="flex flex-row-reverse mt-8">
                          <Input
                            type="submit"
                            value="Envoyer"
                            className="w-24 p-2 text-center text-white bg-indigo-600 rounded-lg cursor-pointer"
                          />
                        </div>
                      </>
                    );
                  }}
                </Form>
              </section>
            )}
          </InView>
        </div>
        {!openNavBar && (
          <button
            ref={scrolluUpButtonRef}
            className="fixed flex items-center justify-center w-10 h-10 p-2 transition duration-500 bg-indigo-400 rounded-full shadow-lg opacity-0 cursor-pointer right-3 bottom-5 animate-bounce ring-1 ring-indigo-900/5 bg-opacity-30"
            onClick={() =>
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              })
            }
          >
            <ArrowUpIcon className="w-5 h-5 text-indigo-700" />
          </button>
        )}
      </div>
    </>
  );
}

export default App;

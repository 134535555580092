import clsx from "clsx";
import React from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";

export type item = {
  title: string;
  href: string;
};

type NavBarProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  items: Array<item>;
};

const NavBar = ({ open, setOpen, items }: NavBarProps) => {
  return (
    <nav className="fixed inset-x-0 top-0 z-20 bg-indigo-500">
      <div className="flex flex-wrap items-center justify-between max-w-screen-lg p-4 mx-auto ">
        <div className="text-white">Curriculum vitae</div>
        <button
          onClick={() => setOpen(!open)}
          className="z-50 flex flex-col justify-between w-6 h-6 cursor-pointer md:hidden group"
        >
          <div
            className={clsx("hamburger-slice", {
              "rotate-45 translate-y-2.5": open,
            })}
          ></div>
          <div
            className={clsx("hamburger-slice", {
              "opacity-0 !duration-100": open,
              "opacity-100 delay-100": !open,
            })}
          ></div>
          <div
            className={clsx("hamburger-slice", {
              "-rotate-45 -translate-y-3": open,
            })}
          ></div>
        </button>
        <section className="items-center hidden space-x-8 text-white md:flex">
          {items.map(({ title, href }) => (
            <AnchorLink
              key={href}
              offset={50}
              href={href}
              className="font-semibold opacity-70 hover:opacity-100"
            >
              {title}
            </AnchorLink>
          ))}
        </section>
        <section
          className={clsx("fixed top-0 right-0 h-screen md:hidden", {
            "z-40": open,
            "z-10 pointer-events-none": !open,
          })}
        >
          <div
            className={clsx(
              "flex flex-col items-center justify-start space-y-5 w-screen max-w-xs h-full pt-20 pl-6 overflow-y-auto font-bold text-white transition duration-500 ease-in-out transform bg-gradient-to-b from-indigo-500 from-20% to-indigo-300",
              {
                "translate-x-full delay-500": !open,
              }
            )}
          >
            {items.map(({ title, href }, index) => (
              <AnchorLink
                key={href}
                offset={50}
                href={href}
                onClick={() => setOpen(!open)}
                className={clsx(`nav-item delay-${index * 100}`, {
                  "opacity-1000 translate-x-0": open,
                  "opacity-0 translate-x-full": !open,
                })}
              >
                {title}
              </AnchorLink>
            ))}
          </div>
        </section>
      </div>
    </nav>
  );
};

export default NavBar;

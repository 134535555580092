import React from "react";
import {
  useForm,
  UseFormReturn,
  SubmitHandler,
  FieldValues,
  DeepPartial,
} from "react-hook-form";

type FormProps<TFormValues extends FieldValues> = {
  onSubmit: SubmitHandler<TFormValues>;
  children: (methods: UseFormReturn<TFormValues>) => React.ReactNode;
  defaultValues?: DeepPartial<TFormValues> | undefined;
};

const Form = <TFormValues extends Record<string, any> = Record<string, any>>({
  onSubmit,
  children,
  defaultValues,
}: FormProps<TFormValues>) => {
  const methods = useForm<TFormValues>({ defaultValues });
  return (
    <form onSubmit={methods.handleSubmit(onSubmit)}>{children(methods)}</form>
  );
};

export default Form;
